// import { parseQueryParams } from '../utilities/parsers'
import { parseQueryParams } from '../utilities/parsers'
import { downloadData, getData, postData, updateFromData } from './api.helpers'
import apiUrl from './api.url'

// const buildUrlWithParams = (baseUrl: string, params: any) => {
//   return `${baseUrl}${parseQueryParams(params)}`
// }
export const getImpersonate = (id: string) => {
  return getData(`${apiUrl.EMPLOYEE_LIST_URL}permissions/${id}/`)
}

export const getCountries = () => getData(apiUrl.COUNTRIES)

export const updateAdminPassword = (employee: string, data: string) => {
  return updateFromData(
    `${apiUrl.ADMIN_USER}/${employee}/change_password`,
    data
  )
}

export const updateAssessorPassword = (id: string, data: string) => {
  return updateFromData(
    `${apiUrl.ASSESSOR_LIST_URL}/${id}/change_password`,
    data
  )
}

export const exportData = (data: any, url: any) =>
  downloadData(`${apiUrl[url]}${parseQueryParams(data)}`)

export const getSettings = () => {
  return getData(`${apiUrl.SETTINGS}`)
}
export const updateSettings = (data: any) => {
  return postData(`${apiUrl.UPDATE_SETTINGS}`, data)
}
